import { Component } from '@angular/core';

import { UserLogadoService } from './shared/user-logado/user-logado.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UserLogadoService]
})
export class AppComponent {
  title = 'app';
}
