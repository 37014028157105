import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserLogadoService } from '../user-logado/user-logado.service';

@Injectable()
export class AcessoGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _userLogado: UserLogadoService
  ){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      //if (this._userLogado.validarAcesso(state.url)){
        return true;
      //}
      this._toastr.info("Seu acesso a esta Página não foi Configurado.");
      return false;
  }

}
