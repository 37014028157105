export class AppSettings {
    public static URL_GESTOR = "";
    public static URL_GESTOR_PRODUCAO = "https://gestorapi.ns.eti.br/v1";
    public static URL_GESTOR_HOMOLOGACAO = "https://gestorapihml.ns.eti.br/v1";

    public static URL_TRIBUTOS = "";
    public static URL_TRIBUTOS_HOMOLOGACAO = "https://apitributoshml.ns.eti.br/v1";
    public static URL_TRIBUTOS_PRODUCAO = "https://apitributos.ns.eti.br/v1";

    public static CHAVE_TOKEN_NAME = "token-tributos-api";
    
    public static emProducao = false;

    public static VERSAO = {
        nome: 'NS Tributos',
        numero: '1.0',
        apelido: 'Tributos'
    }

}